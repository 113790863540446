<template>
  <div class="hh_video_wrap">
    <div class="wxq_teacher" v-for="(item, index) in datas" :key="index">
      <div class="wxq_teacher_photo">
        <img :src="item.avatar " alt=""/>
      </div>
      <p class="wxq_teacher_name">{{ item.teaName }}</p>
      <p class="wxq_teacher_specialty">{{ item.teaTitle }}</p>
      <button class="btn btn_blue" @click="getDetail(item)">查看详情</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'teacherCom',
  props: ['teacherData'],
  data() {
    return {
      datas: [],
    }
  },
  watch: {
    'teacherData': {
      immediate: true,
      handler(val) {
        if (val) {
          this.datas = val
        }
      },
      deep: true
    }
  },
  methods: {
    getDetail(item) {
      if(!sessionStorage.getItem('educationToken')) {
        this.$store.state.isLogin = true
      }else {
        if(item.teaId) {
          this.$router.push({
            path: '/teacherDetail',
            query: {
              id: item.teaId,
            }
          })
        }
      }
    },
  },
}
</script>
<style lang='scss' scoped>
@import 'src/assets/css/studentsEndCSS/home';
.wxq_teacher {
  position: relative;
  overflow: hidden;
  margin: 0px 7px 15px 7px;
  padding: 12px;
  width: 280px;
  height: 280px;
  text-align: center;
  background: #fff;
  box-shadow: 0 6px 58px 0 rgba(196, 203, 214, .1);
  border-radius: 4px;
  .wxq_teacher_photo {
    width: 110px;
    height: 110px;
    border: 3px #3F8CFF solid;
    border-radius: 50%;
    margin: 5px auto 10px;
    padding: 4px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  p {
    line-height: 32px;
  }
  .wxq_teacher_name {
    width: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 20px;
  }
  .wxq_teacher_specialty {
    font-size: 16px;
    color: #91929E;
    padding: 0 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .btn {
    width: 100%;
    margin-top: 24px;
  }
}
</style>
